<script setup lang="ts">
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { MAX_NAME_LENGTH } from '@@/bits/numbers'
import { SnackbarNotificationType } from '@@/enums'
import OzAvatar from '@@/library/v4/components/OzAvatar.vue'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzInput, { OzInputSizePreset } from '@@/library/v4/components/OzInput.vue'
import OzLoadingSpinner from '@@/library/v4/components/OzLoadingSpinner.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceButtonSkinBasedTwClasses } from '@@/vuecomposables/surface_button_skin_based_tw_classes'
import tinykeys from 'tinykeys'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const AVATAR_SIZE = 24
const EXPAND_LABEL_TIMEOUT = 1000
const COLLAPSE_LABEL_WITHOUT_INTERACTING_TIMEOUT = 5000
const COLLAPSE_LABEL_AFTER_INTERACTING_TIMEOUT = 2000

const surfaceStore = useSurfaceStore()
const { buttonSkinBasedTailwindClasses } = useSurfaceButtonSkinBasedTwClasses()
const surfaceGuestStore = useSurfaceGuestStore()
const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
const globalSnackbarStore = useGlobalSnackbarStore()

const isExpanded = ref<boolean>(false)
const isEditing = ref<boolean>(false)
const userNameInputRef = ref<InstanceType<typeof OzInput>>()
const isNameInputFocused = ref<boolean>(false)
const guestIdLabelRef = ref<InstanceType<typeof OzBaseButton>>()

let labelTimeout

const isDoneButtonDisabled = computed<boolean>(() => {
  return (
    surfaceGuestStore.userName.trim() === '' ||
    !surfaceGuestStore.isUserNameValid ||
    surfaceGuestStore.isAwaitingServerResponse
  )
})

function stopLabelCollapseTimer() {
  if (!isExpanded.value) return

  clearTimeout(labelTimeout)
}

function startCollapseLabelTimer() {
  labelTimeout = setTimeout(() => {
    // Collapses the label after 2 seconds if the user doesn't interact with it, e.g. click or hover
    isExpanded.value = false
  }, COLLAPSE_LABEL_AFTER_INTERACTING_TIMEOUT)
}

function enterEditMode(): void {
  stopLabelCollapseTimer()
  nextTick(() => {
    isEditing.value = true
    setTimeout(() => {
      // Need to check it because the OzInput is placed inside the OzBaseButton,
      // and pressing the key space with trigger the click event of the OzBaseButton
      if (isNameInputFocused.value) return
      userNameInputRef.value?.focusInput()
      userNameInputRef.value?.selectInput()
    })
  })
}

async function saveName(): Promise<void> {
  if (isDoneButtonDisabled.value) return
  await surfaceGuestStore.updateSessionUserName()
  if (surfaceGuestStore.userNameErrorMessage !== '') {
    globalSnackbarStore.setSnackbar({
      message: surfaceGuestStore.userNameErrorMessage,
      notificationType: SnackbarNotificationType.error,
    })
  } else if (surfaceGuestStore.isUserNameValid) {
    startCollapseLabelTimer()
    isEditing.value = false
  }
}

function handleOnMouseLeaveOrBlur(): void {
  if (isEditing.value || !isExpanded.value) return
  // Don't collapse the label if the user is editing the name
  startCollapseLabelTimer()
}

function handleClickLabel(): void {
  if (isExpanded.value) {
    enterEditMode()
  } else {
    surfaceGuestStore.showGuestIdCard()
  }
}

function handleClickOutsideLabel(e: MouseEvent): void {
  if (!isExpanded.value || isEditing.value) return
  if (!guestIdLabelRef.value?.$el.contains(e.target as Node)) {
    isExpanded.value = false
  }
}

let removeKeyboardShortcutsListener = (): void => {}

watch(isExpanded, (newValue) => {
  if (!newValue) {
    removeKeyboardShortcutsListener()
  }
})

onMounted(() => {
  window.addEventListener('click', handleClickOutsideLabel)
  surfaceGuestStore.setUserName(surfaceGuestStore.displayName)

  nextTick(() => {
    if (!surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape) {
      setTimeout(() => {
        // Expands the label 1 second after the user visits the page
        isExpanded.value = true
        removeKeyboardShortcutsListener = tinykeys(window, {
          Escape: () => {
            isExpanded.value = false
          },
        })
      }, EXPAND_LABEL_TIMEOUT)

      labelTimeout = setTimeout(() => {
        // Collapses the label after 5 seconds if the user hasn't interacted with it, e.g. click or hover
        isExpanded.value = false
      }, COLLAPSE_LABEL_WITHOUT_INTERACTING_TIMEOUT)
    }
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutsideLabel)
  removeKeyboardShortcutsListener()
})
</script>

<template>
  <OzBaseButton
    ref="guestIdLabelRef"
    data-testid="surfaceGuestIdLabel"
    :aria-label="isExpanded ? __('Edit guest name') : __('Show change guest menu')"
    :title="isExpanded ? __('Edit guest name') : __('Show change guest menu')"
    :class="[
      'group',
      'rounded-2xl overflow-hidden p-0',
      'h-10 box-border',
      isExpanded && {
        'relative z-modal-dropdown': true,
        'w-71': true,
        'bg-light-ui-100 dark:bg-dark-ui-100':
          !surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape && !surfaceStore.isSkinColorAvailable,
      },
      !isExpanded && {
        'w-10': !surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape,
        'bg-transparent': surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape,
        ...buttonSkinBasedTailwindClasses,
      },

      // Focus
      'focus-visible:ring-[2.5px]',
      {
        'ring-grape-500': surfaceStore.buttonDarkMode === false,
        'ring-canary-500': surfaceStore.buttonDarkMode === true,
        'ring-grape-500 dark:ring-canary-500': surfaceStore.buttonDarkMode === 'auto',
      },
      // Active
      {
        'hover-hover:active:bg-grape-500': surfaceStore.buttonDarkMode === false,
        'hover-hover:active:bg-canary-500': surfaceStore.buttonDarkMode === true,
        'hover-hover:active:bg-grape-500 hover-hover:dark:active:bg-canary-500': surfaceStore.buttonDarkMode === 'auto',
      },

      // Transition
      'transition-all motion-reduce:transition-none',
      'ease-in-out',
      {
        'duration-180': isExpanded,
        'duration-300': !isExpanded,
      },
    ]"
    :style="
      (isExpanded || (!isExpanded && surfaceStore.isSectionBreakout)) &&
      !surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape &&
      surfaceStore.isSkinColorAvailable
        ? { backgroundColor: surfaceStore.skinPrimaryVariantColorAsRGB }
        : {}
    "
    @mouseenter="stopLabelCollapseTimer"
    @focus="stopLabelCollapseTimer"
    @blur="handleOnMouseLeaveOrBlur"
    @mouseleave="handleOnMouseLeaveOrBlur"
    @click.stop="handleClickLabel"
  >
    <div
      :class="[
        'flex items-center',
        'h-full box-border',
        isExpanded && {
          'justify-between': true,
          'px-2 py-1': true,
          ...buttonSkinBasedTailwindClasses,
        },
        !isExpanded && {
          'justify-end': true,
        },
      ]"
    >
      <div v-if="isExpanded" class="flex flex-col grow text-start min-w-0">
        <p
          :class="[
            'text-body-tiny ps-1 truncate',
            {
              'text-dark-text-200': surfaceStore.buttonDarkMode === false,
              'text-light-text-200': surfaceStore.buttonDarkMode === true,
              'text-dark-text-200 dark:text-light-text-200': surfaceStore.buttonDarkMode === 'auto',
            },
          ]"
        >
          {{ __('Contributing as guest') }}
        </p>
        <span v-if="!isEditing" class="flex items-center grow space-s-1 -mt-0.5 me-4.5">
          <span
            :class="[
              'flex text-body-small min-w-0 px-1 rounded',
              {
                'group-hover:bg-[rgba(255,255,255,0.32)]': surfaceStore.buttonDarkMode === false,
                'group-hover:bg-[rgba(0,0,0,0.24)]': surfaceStore.buttonDarkMode === true,
              },
            ]"
          >
            <span
              data-testid="surfaceGuestIdLabelUserName"
              :class="[
                'font-semibold',
                'truncate',
                {
                  'text-dark-text-100': surfaceStore.buttonDarkMode === false,
                  'text-light-text-100': surfaceStore.buttonDarkMode === true,
                  'text-dark-text-100 dark:text-light-text-100': surfaceStore.buttonDarkMode === 'auto',
                },
              ]"
            >
              {{ surfaceGuestStore.displayName }}
            </span>
          </span>

          <OzPlainButton
            data-testid="surfaceGuestIdLabelEditButton"
            :aria-label="__('Edit guest name')"
            :size-preset="OzPlainButtonSizePreset.H18px"
            :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
            :dark-mode="surfaceStore.buttonDarkMode"
            @focus="stopLabelCollapseTimer"
            @blur="handleOnMouseLeaveOrBlur"
            @click.stop="enterEditMode"
            @keydown.enter.stop="enterEditMode"
          >
            <template #icon>
              <OzIcon name="pencil_outline" :size="16" />
            </template>
          </OzPlainButton>
        </span>
        <form v-else novalidate class="flex items-center grow space-s-2 -mt-0.5 me-4.5" @submit.prevent="saveName">
          <OzInput
            ref="userNameInputRef"
            test-id="surfaceGuestIdLabelUserNameInput"
            name="username"
            autocomplete="off"
            type="text"
            :max-length="MAX_NAME_LENGTH"
            :x-border="!surfaceGuestStore.isUserNameValid"
            :dark-mode="surfaceStore.buttonDarkMode"
            :model-value="surfaceGuestStore.userName"
            :size-preset="OzInputSizePreset.H20px"
            :valid="surfaceGuestStore.isUserNameValid ? 'yes' : 'no'"
            :disabled="surfaceGuestStore.isAwaitingServerResponse"
            :class="[
              {
                'bg-[rgba(255,255,255,0.32)]': surfaceStore.buttonDarkMode === false,
                'bg-[rgba(0,0,0,0.24)]': surfaceStore.buttonDarkMode === true,
              },
            ]"
            @focusin="isNameInputFocused = true"
            @focusout="isNameInputFocused = false"
            @blur="isNameInputFocused = false"
            @update:modelValue="surfaceGuestStore.setUserName"
          />

          <OzLoadingSpinner
            v-if="surfaceGuestStore.isAwaitingServerResponse"
            :color="surfaceStore.buttonDarkMode ? 'black' : 'white'"
            :size="15"
          />
          <template v-else>
            <OzPlainButton
              v-if="surfaceGuestStore.isUserNameValid"
              data-testid="surfaceGuestIdLabelSaveButton"
              :aria-label="__('Save guest name')"
              :size-preset="OzPlainButtonSizePreset.H18px"
              :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
              :dark-mode="surfaceStore.buttonDarkMode"
              :disabled="isDoneButtonDisabled"
            >
              <template #icon>
                <OzIcon name="checkmark_thin" :size="16" />
              </template>
            </OzPlainButton>
            <OzIcon v-else name="warning" :size="16" />
          </template>
        </form>
      </div>

      <div
        :class="[
          'flex justify-center',
          !isExpanded && !surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape && 'w-10',
        ]"
      >
        <OzAvatar
          data-testid="surfaceGuestIdLabelAvatar"
          class="rounded-full pointer-events-none"
          :width="AVATAR_SIZE"
          :height="AVATAR_SIZE"
          :src="surfaceStore.user.avatar"
          :alt-text-name="surfaceGuestStore.displayName"
        />
      </div>
    </div>
  </OzBaseButton>
</template>
