// @file Composable to encapsulate button skin based Tailwind classes for buttons on the surface
import { useSurfaceStore } from '@@/pinia/surface'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export const useSurfaceButtonSkinBasedTwClasses = (): Record<string, boolean | any> => {
  const surfaceStore = useSurfaceStore()
  const { isMobileLayout, isSectionBreakout, isSkinColorAvailable, buttonDarkMode } = storeToRefs(surfaceStore)

  const buttonSkinBasedTailwindClasses = computed(() => {
    if (isSectionBreakout.value) {
      return {}
    }

    if (isMobileLayout.value) {
      return {
        'aria-disabled:!bg-transparent': true,
      }
    }

    if (!isSkinColorAvailable.value) {
      return {}
    }

    // buttonDarkMode is either true or false here (cannot be 'auto') since we checked for isSkinColorAvailable
    return {
      // Base
      'bg-grey-1000 bg-opacity-5': buttonDarkMode.value === false,
      'bg-white-100 bg-opacity-10': buttonDarkMode.value === true,
      'text-dark-text-200': buttonDarkMode.value === false,
      'text-light-text-200': buttonDarkMode.value === true,

      // Hovered
      'hover-hover:hover:bg-opacity-10': buttonDarkMode.value === false,
      'hover-hover:hover:bg-opacity-15': buttonDarkMode.value === true,

      // Disabled
      'aria-disabled:!bg-highway-400': buttonDarkMode.value === false,
      'aria-disabled:!bg-white-disabled': buttonDarkMode.value === true,
    }
  })

  const buttonSkinBasedTailwindClassesWithoutHover = computed(() => {
    if (isSectionBreakout.value) {
      return {}
    }

    if (isMobileLayout.value) {
      return {
        'aria-disabled:!bg-transparent': true,
      }
    }

    if (!isSkinColorAvailable.value) {
      return {}
    }

    return {
      // Base
      'bg-grey-1000 bg-opacity-5': buttonDarkMode.value === false,
      'bg-white-100 bg-opacity-10': buttonDarkMode.value === true,
      'text-dark-text-200': buttonDarkMode.value === false,
      'text-light-text-200': buttonDarkMode.value === true,

      // Disabled
      'aria-disabled:!bg-highway-400': buttonDarkMode.value === false,
      'aria-disabled:!bg-white-disabled': buttonDarkMode.value === true,
    }
  })
  return { buttonSkinBasedTailwindClasses, buttonSkinBasedTailwindClassesWithoutHover }
}
